import React from 'react'
import { Link } from 'gatsby'
// import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Flex, Stack, Heading, Text, Grid } from '@chakra-ui/react'
import { FaRegClock } from 'react-icons/fa'

const BlogPostCard = ({ post }) => {
  console.log({ post })
  // const image = post.frontmatter.image.childImageSharp.fluid
  return (
    <Grid
      as="article"
      border="1px"
      borderColor="gray.100"
      borderRadius="lg"
      boxShadow="xl"
    >
      <Link to={post.frontmatter.path}>
        {/* {Boolean(image) && (
          <GatsbyImage
            alt={`${post.frontmatter.title}- Featured Shot`}
            fluid={image}
          />
        )} */}
      </Link>
      <Stack as="header" p={5} spacing={2}>
        <Heading className="post-title" fontSize="xl">
          <Link to={post.frontmatter.path}>{post.frontmatter.title}</Link>
        </Heading>
        <Text fontSize="md">{post.frontmatter.description}</Text>
        <Flex
          alignItems="center"
          color="gray.600"
          fontSize="sm"
          justifyContent="space-between"
        >
          <Text>{post.frontmatter.date}</Text>
          <Flex alignItems="center">
            <Box as={FaRegClock} mr={2} />
            {post.timeToRead} min read
          </Flex>
        </Flex>
      </Stack>
    </Grid>
  )
}
export { BlogPostCard }
