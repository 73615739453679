import React from 'react'
import { graphql } from 'gatsby'
import { Grid, Box, Heading } from '@chakra-ui/react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { BlogPostCard } from '../components/blog-post-card'

const Blog = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  return (
    <Layout>
      <SEO title="Blog" description="" />
      <Heading as='h1'>From the blog</Heading>
      <Grid width="100%" templateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']} gridGap='32px' mt={10}>
        {
          edges.map(edge => <BlogPostCard key={edge.node.id} post={edge.node} />)
        }

      </Grid>
    </Layout>
  )
}

export const pageQuery = graphql`
  query blogPageQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            description
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          timeToRead
        }
      }
    }
  }
`

export default Blog
